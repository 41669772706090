window.AppConfig = {
  APP_NAME: "#{appName}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
  AUTH_MODE: "#{authMode}#",
  ENVIRONMENT: "#{environment}#",
  API_URL: "#{apiUrl}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  PRODUCTS_API_URL: "#{productsApiUrl}#",
  OLD_PORTAL_URL: "#{oldPortalUrl}#",
  LINK_URL: "#{linkUrl}#",
  CONNECTOR_HEALTH_URL: "#{connectorHealthUrl}#",
  CONNECTORS_API_URL: "#{connectorsApiUrl}#",
  PORTAL_URL: "#{portalUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  USER_MANAGEMENT_API_URL: "#{userManagementApiUrl}#",
  DATA_UI_URL: "#{dataUiUrl}#",
  FILES_UI_URL: "#{filesUiUrl}#",
  INTEGRATIONS_UI_URL: "#{integrationsUiUrl}#",
  SYNC_HEALTH_UI_URL: "#{syncHealthUiUrl}#",
  PRODUCTS_UI_URL: "#{productsUiUrl}#",
  ASSESS_UI_URL: "#{assessUiUrl}#",
  AUTH_SETTINGS_UI_URL: "#{authSettingsUiUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  GA_TRACKING_ID_CONSOLIDATED_STREAM: "#{gaIdConsolidatedStream}#",
  VISUALIZE_URL: "#{visualizeUrl}#",
  VISUALIZE_COMMERCE_URL: "#{visualizeCommerceUrl}#",
  GTM_ID: "#{gtmId}#",
  PARDOT_A_ID: "#{pardotAId}#",
  PARDOT_C_AD: "#{pardotCId}#",
  ZAPIER_TOKEN_MIGRATION_URL: "#{zapierTokenMigrationUrl}#",
  ZAPIER_TRACK_BUILDING_URL: "#{zapierTrackBuildingUrl}#",
  FEATURES_UI_URL: "#{featuresUiUrl}#",
  KNOWLEDGE_UI_URL: "#{knowledgeUiUrl}#",
  PRODUCT_PAGES_UI_URL: "#{productPagesUiUrl}#",
  DATA_HISTORY_UI_URL: "#{dataHistoryUiUrl}#",
  SYNCHRONIZATION_SETTINGS_UI_URL: "#{synchronizationSettingsUiUrl}#",
  CLIENT_CREDENTIALS_API_URL: "#{clientCredentialsApiUrl}#",
  RM_PORTAL_URL: "#{rmPortalUrl}#",
  USERFLOW_TOKEN: "#{userflowToken}#",
  AUTH0_CLIENTID: "#{auth0ClientId}#",
  AUTH0_DOMAIN: "#{auth0Domain}#",
};

window.UserManagementAppConfig = {
  USER_MANAGEMENT_API_URL: "#{userManagementApiUrl}#",
  PORTAL_API_URL: "#{portalApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  PORTAL_URL: "#{portalUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  ENVIRONMENT: "#{environment}#",
  SIGNUP_URL: "#{signUpUrl}#",
};

window.DataAppConfig = {
  ENVIRONMENT: "#{environment}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementApiUrl}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  PORTAL_API_URL: "#{apiUrl}#",
  PRODUCTS_API_URL: "#{productsApiUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  DATA_INTEGRITY_URL: "#{dataIntegrityUrl}#",
  LINKS_URL: "#{linksUrl}#",
};

window.SyncHealthConfig = {
  ENVIRONMENT: "#{environment}#",
  API_URL: "#{syncHealthApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
};

window.IntegrationsAppConfig = {
  ENVIRONMENT: "#{environment}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  INTEGRATIONS_API_URL: "#{integrationsApiUrl}#",
  PORTAL_API_URL: "#{apiUrl}#",
  CONNECTORS_API_URL: "#{connectorsApiUrl}#",
  CLIENT_CREDENTIALS_API_URL: "#{clientCredentialsApiUrl}#",
  NCINO_API_URL: "#{nCinoApiUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
};

window.AssessConfig = {
  PUBLIC_API_URL: "#{publicApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  DATA_INTEGRITY_URL: "#{dataIntegrityUrl}#",
  ASSESS_URL: "#{assessUrl}#",
  PRODUCTS_API_URL: "#{productsApiUrl}#",
  ENVIRONMENT: "#{environment}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  PORTAL_URL: "#{portalUrl}#",
};

window.ProductsAppConfig = {
  APP_NAME: "#{appName}#",
  ENVIRONMENT: "#{environment}#",
  PRODUCTS_API_URL: "#{productsApiUrl}#",
  PORTAL_API_URL: "#{apiUrl}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
};

window.AuthSettingsConfig = {
  ENVIRONMENT: "#{environment}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  LINK_URL: "#{linkUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  PORTAL_URL: "#{portalUrl}#",
};
window.LinkSettingsConfig = window.AuthSettingsConfig;

window.FeaturesAppConfig = {
  ENVIRONMENT: "#{environment}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  FEATURES_API_URL: "#{featuresApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
};

window.DataHistoryAppConfig = {
  ENVIRONMENT: "#{environment}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
};

window.ProductPagesAppConfig = {
  ENVIRONMENT: "#{environment}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  INTEGRATIONS_API_URL: "#{integrationsApiUrl}#",
  CLIENT_CREDENTIALS_API_URL: "#{clientCredentialsApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
};

window.SynchronizationSettingsAppConfig = {
  ENVIRONMENT: "#{environment}#",
  PORTAL_URL: "#{portalUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
  PUBLIC_API_URL: "#{publicApiUrl}#",
  IDENTITY_SERVER_URL: "#{identityServerUrl}#",
  USER_MANAGEMENT_URL: "#{userManagementUrl}#",
  APP_CLIENT_COOKIE_NAME: "#{appClientCookieName}#",
};

window.KnowledgeConfig = {
  APP_NAME: "#{appName}#",
  ENVIRONMENT: "#{environment}#",
  KNOWLEDGE_API_URL: "#{knowledgeApiUrl}#",
  SIGNUP_URL: "#{signupUrl}#",
  AMPLITUDE_API_KEY: "#{amplitudeApiKey}#",
};
